.fb_board {
  position: relative;
  width: 60vw;
  height: 87vh;
  border: 1px solid #007485;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.fb_formName_container {
  position: absolute;
  top: -20px;
  width: 30%;
  height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: rgb(255, 239, 210);
  background-color: #218ea0;
  border: 1px solid rgb(70, 70, 70);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fb_formName_container span {
  font-size: 14px;
  font-weight: 500;
}

.fb_saveForm_btn,
.fb_saveAndCloseForm_btn {
  width: 120px;
  padding: 7px 10px;
  position: absolute;
  top: 7px;
  right: 10px;
  color: #1a7e8f;
  background: transparent;
  border: 1px solid #1a7e8f;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.fb_saveForm_btn:hover,
.fb_saveAndCloseForm_btn:hover {
  opacity: 0.9;
  color: white;
  background-color: #1a7e8f;
}
.fb_saveForm_btn {
  top: 7px;
  right: 140px;
}
.fb_discardForm_btn {
  width: 120px;
  padding: 7px 10px;
  position: absolute;
  top: 7px;
  left: 10px;
  color: #808080;
  background: transparent;
  border: 1px solid #808080;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.fb_discardForm_btn:hover {
  opacity: 0.9;
  color: white;
  background-color: #808080;
}

.inputfields {
  position: relative;
  width: 98%;
  height: 92%;
  margin-top: 5px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #707070;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow-y: auto;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: hidden;
}

.kb_kanban_container {
  width: 80%;
  min-height: 200px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  overflow-y: scroll;
}
.kb_kanban_container .kb_kanban_row {
  width: 95%;
  display: flex;
  padding-top: 20px;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}
.kb_kanban_container .kb_kanban_row .kb_kanban_column {
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border: 1px dashed #868686;
}
.kb_kanban_container .kb_kanban_row .kb_kanban_column button.kbnbtn {
  width: 100px;
  height: 35px;
  padding: 5px;
  border-radius: 5px;
}
.kb_kanban_container .kb_kanban_row .kb_kanban_column button.del_button {
  position: absolute;
  top: 2px;
  right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  background-color: #3b3b3b;
  border: none;
}

.kb_kanban_container .kb_kanban_row .kb_kanban_column span {
  color: #181818;
}

.lb_list_container {
  width: 80%;
  min-height: 200px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  gap: 0px;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: flex-start;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: auto;
}

.lb_list_container .lb_list_column {
  width: max-content;
  /* width: "20px"; */
  color: black !important;
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #383838;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lb_list_container .lb_list_column span {
  max-width: 100px;
  margin-right: 10px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lb_list_cell {
  width: 100%;
  height: 56px;
  background-color: #ddd;
}

.fb_group1,
.fb_group2 {
  padding: 2px 20px 20px 20px;
  position: relative;
  width: 100%;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.fb_group1 {
  height: 10rem;
}

.fb_group2 {
  height: 15rem;
  gap: 5px;
  overflow: auto;
}

.fb_group_label {
  margin-bottom: 5px;
  position: relative;
  align-self: flex-start;
  top: 2px;
  left: -10px;
  font-size: 16px;
}

.fb_group_span_msg {
  margin-top: 15px;
  align-self: center;
  justify-self: center;
}

.fb_row {
  width: 90%;
  display: flex;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.fb_tab_row {
  width: 90%;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.tab-content {
  justify-content: flex-start !important;
}

.fb_row.placeholder {
  margin-top: 10px;
  border: 1px dashed #ccc;
  background-color: #d6d6d6;
  height: 60px;
  width: 100%;
}

.fb_inputfield {
  width: 100%;
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px dotted rgba(94, 94, 94, 0.856);
  gap: 3px;
}

.fb_inputfield.placeholder {
  border: 1px dashed #ccc;
  background-color: #f9f9f9;
  height: 40px;
}

.dragged-over {
  border: 2px dashed #000;
  background-color: #f0f0f0;
}

.fb_inputfield label {
  font-size: 14px;
}

.fb_inputfield input,
select {
  width: 95%;
  height: 30px;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  outline: none;
  color: rgb(95, 95, 95);
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}
.fb_inputfield input[type="file"] {
  width: auto;
  height: auto;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  outline: none;
  color: rgb(95, 95, 95);
  border: 1px solid #ddd;
  border-radius: 5px;
}

.fb_inputfield button[type="submit"] {
  width: 100px;
  height: 35px;
  padding: 5px;
  border-radius: 5px;
}

.fb_group {
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid #616161;
  padding: 10px;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-bottom: 20px;
}

.fb_groupRow {
  width: 100%;
  display: flex;
  gap: 10px;
}

.fb_groupCol {
  flex: 1;
  padding: 10px;
  border: 1px dotted #868686;
  background-color: #5c5c5c;
}
.nav-tabs .nav-link {
  color: #727272;
}
.nav-tabs .nav-item .active {
  color: #4d4d4d !important;
  background-color: transparent !important;
}
.nav-tabs .nav-link:focus {
  color: #4d4d4d;
}

.tab-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ribbon_container {
  position: absolute;
  right: -20px;
  top: 20px;
  width: 225px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  color: white;
  transform: rotate(25deg);
}

.table_newfield_add_button {
  margin-top: 2px;
  margin-right: 5px;
  border: 1px solid #007485;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 13px;
  color: white;
  background-color: #007485;
}
.table_newfield_add_button:hover {
  background-color: #03abc5;
}
.table_newfield_option_add_button {
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 13px;
  color: white;
  background-color: #808080;
}
.table_newfield_option_add_button:hover {
  background-color: #3f3f3f;
}
.table_field_remove_button {
  border: none;
  padding: 2px 5px;
  color: white;
  background-color: #dc3545;
  font-size: 18px;
  border-radius: 5px;
}
.table_field_remove_button:hover {
  background-color: #c21d2e;
}

@keyframes marquee {
  from {
    transform: translateX(0);
    /* transform: translateX(100%); */
  }
  to {
    transform: translateX(-100%);
  }
}