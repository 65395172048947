.pm_topbar_container {
  position: absolute;
  top: 0px;
  width: 30%;
  height: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  background-color: #218ea0;
  /* background-color: #0682f5; */
  /* background-color: #684b60; */
  /* background-color: #353535; */
  border: 1px solid rgb(119, 118, 118);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.pm_topbar_container span {
  font-size: 14px;
  font-weight: 500;
}
