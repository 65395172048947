.selected_field_tag {
  width: auto;
  height: 18px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.selected_field_tag .tag_remove_button {
  width: 20px;
  height: 18px;
  font-size: 8px;
  background-color: #ccc;
  color: #383838;
  border: 1px solid #ddd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /* z-index: -1; */

  &:hover {
    background-color: #ffabab;
    color: #ff2525;
  }
}
