.download-image .download-btn {
  width: 150px;
  position: absolute;
  top: -10px;
  right: 0px;
  border: 1px solid #007485;
  /* border: 1px solid #2c57ed; */
  /* border: 1px solid #eee; */
  color: #007485;
  /* color: #2c57ed; */
  background: transparent;
  /* background: #474747; */
  padding: 7px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.download-image .download-btn:hover {
  opacity: 0.9;
  color: white;
  background-color: #007485;
  /* background-color: #2c57ed; */
  /* background-color: #1d1d1d; */
}

.export-btn {
  top: 5px !important;
  right: 175px !important;
  z-index: 10;
}

.export_record_btn {
  position: relative;
  left: 5px;
  width: 130px;
  color: #007485;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid #007485;
  border-radius: 5px !important;
  padding: 7px 10px;
  transition: all;
  transition-duration: 300ms;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
  width: 140px;
  left: 0px;
  color: #fff;
  background-color: #007485;
}
}
/* .export_record_btn:hover {
  width: 140px;
  left: 15px;
  color: #fff;
  background-color: #007485;
} */