.donor_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.donor_row {
  display: flex;
  gap: 22px;
}
.donor_dash_left {
  width: 20%;
  background: white;
  height: 100%;
}
.donor_dash_side {
  height: 100vh;
}
.donor_side_menu {
  padding: 15px;
}
.donor_dash_right {
  width: 78%;
  padding: 20px 30px 20px 0px;
  background: #f2f2f2;
}
@media screen and (max-width: 992px) {
  .donor_dash_left {
    width: 40%;
  }
  .donor_dash_right {
    width: 60%;
  }
}
.donor_dash_deatil {
  background: white;
  border-radius: 10px;
  padding: 18px;
  margin: 22px 0px;
  box-shadow: 1px 4px 4px #65686826;
}
.donor_dash_deatil:first-child {
  margin-top: 0px;
}
.donor_dash_deatil:last-child {
  margin-bottom: 0px;
}

@media screen and (max-width: 992px) {
  .donor_dash_deatil:first-child {
    margin-bottom: 16px;
  }
  .donor_dash_deatil:last-child {
    margin-top: 16px;
  }
}
.donor_detail {
  background: #f2f4f4;
  padding: 10px 14px 15px 12px;
  border-radius: 8px;
  color: #214242;
}
ul.donor_menu {
  padding-left: 0rem;
}
.donor_menu li {
  list-style: none;
}
.donor_menu a {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #214242;
  padding: 13px 16px 13px 16px;
  transition: all 0.3s ease;
  border-radius: 6px;
  margin: 8px 0;
  font-weight: 500;
  text-decoration: none;
}
.donor_menu > li > a.active,
.donor_menu > li > a.active:hover {
  background: #007485;
  /* background: #2c57ed; */
  color: white;
}
.donor_menu .icon {
  min-width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.donor_menu li a:hover {
  background: #f2f2f382;
}
.donor_kit h2 {
  color: #214242;
  font-size: 18px;
  font-weight: 500;
  margin-top: 6px;
}
.donor_kit p {
  font-size: 14px;
  color: #889a9a;
}
.donor_kit_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
.donor_table {
  position: relative;
  overflow-x: auto;
}
.donor_table table {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #0000000f;
  border-spacing: 0;
}
.donor_table table th {
  border-bottom: 1px solid #0000001c;
  border-right: 1px solid #0000001c;
  background: #f2f4f4;
  color: #214242d6;
  font-weight: 400;
  font-size: 13px;
  padding: 10px;
  text-align: left;
}
.donor_table table td {
  padding: 10px;
  font-size: 14px;
  color: #214242;
  font-weight: 500;
}
.donor_table table tr {
  border-bottom: 1px solid #0000001c;
}
.donor_table table th:first-child {
  border-radius: 8px 0 0 0;
}
.donor_table table th:last-child {
  border-right: none;
  border-radius: 0 8px 0 0;
}
.donor_table .td1 {
  text-align: center;
  padding: 7rem 17rem;
}
.donor_table .td_desc1 {
  color: #214242;
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
}
.donor_table .td_desc2 {
  font-size: 14px;
  color: #889a9a;
}

@media screen and (max-width: 992px) {
  .donor_table .td1 {
    padding: 2rem 2rem;
  }
}

/* ============================================================================================================== */

.App {
  background: #f2f2f2;
}
.td_action_cell .dash_table_icon_edit {
  margin-right: 5px;
  cursor: pointer;
}
.td_action_cell .dash_table_icon_edit,
.td_action_cell .dash_table_icon_del {
  cursor: pointer;
  font-size: 18px;
}
.td_action_cell .dash_table_icon_edit:hover {
  color: #007485;
}
.td_action_cell .dash_table_icon_del:hover {
  color: rgb(255, 81, 0);
}

.dashboard_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.dashboard_buttons button {
  border: 1px solid #007485;
  color: #007485;
  background: transparent;
  padding: 7px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.dashboard_buttons button:hover {
  opacity: 0.9;
  color: white;
  background-color: #007485;
}

.form-check-input:checked {
  background-color: #007485 !important;
  border-color: #007485 !important;
}

.configure_connect_button {
  border: 1px solid #007485;
  color: #007485;
  background: transparent;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    color: white;
    background-color: #007485;
  }
}
.scheduler_action_btn {
  background-color: #ffcccb !important;
  border-color: #f16969 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 56, 56%29%27/%3e%3c/svg%3e") !important;
  /* background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27red%27/%3e%3c/svg%3e') !important; */
}

.scheduler_action_btn:checked {
  background-color: #007485;
  border-color: #007485;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27white%27/%3e%3c/svg%3e") !important;
}
