.email_template_modal {
  --bs-modal-width: 670px;
  --bs-modal-zindex: 1055;
  --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.email_template_modal_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.email_template_modal_body,
.email_template_modal_header,
.email_template_modal_footer,
.email_template_create_modal {
  width: 100%;
}

.modal-body .emailTemplateModalForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal-body .emailTemplateModalForm .emailTemplateModalForm_inputField {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
}
.modal-body .emailTemplateModalForm .emailTemplateModalForm_inputField label {
  font-size: 16px;
  font-weight: 500;
  color: rgb(77, 77, 77);
}
.modal-body .emailTemplateModalForm .emailTemplateModalForm_inputField input {
  width: 100%;
  height: 30px;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 2px 2px 2px 10px;
  color: rgb(77, 77, 77);
}

.modal-body .emailTemplateModalForm .emailTemplateModalForm_inputField span {
  color: rgb(77, 77, 77);
}
