@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rozha+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Outfit:wght@100..900&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  width: 100%;
  height: auto;
}
body {
  width: 100%;
  height: auto;
  background-position: center;
  font-family: "Outfit", sans-serif;
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Rubik", sans-serif; */
  font-optical-sizing: auto;
}
body.background-image {
  height: 82vh;
  /* background-image: url("../public/backgroundimg.avif"); */
  background-repeat: no-repeat;
  background-size: cover;
}
