.modal-body {
  padding: 1rem 3rem;
}
.modal-body .groupModalForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
}
.modal-body .groupModalForm .groupModalForm_inputField {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
}
.modal-body .groupModalForm .groupModalForm_inputField label {
  font-size: 16px;
  font-weight: 450;
  color: rgb(77, 77, 77);
}
.modal-body .groupModalForm .groupModalForm_inputField select {
  width: 95%;
  height: 30px;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 40px 2px 10px;
  color: rgb(77, 77, 77);
}
.modal-body .groupModalForm .groupModalForm_inputField input {
  width: 95%;
  height: 30px;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 40px 2px 10px;
  color: rgb(77, 77, 77);
}
.modal-body .groupModalForm .groupModalForm_inputField button {
  width: 35px;
  top: 32px;
  height: 40px;
  position: absolute;
  right: 20px;
  outline: none;
  border: 1px solid gray;
  color: rgb(77, 77, 77);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal_save_button{
    background-color: #1a7e8f;
    color: white;
    &:hover {
        background-color: #109ab3;
        color: white;
    }
}