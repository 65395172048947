.registerPage {
  width: 40%;
  height: 70%;
  padding: 10px 5px 10px 5px;
  background-color: white;
  box-shadow: 0px 5px 10px black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.registerPage form div input[type="text"],
.registerPage form div[class="mb-3"] input[type="email"],
.registerPage form div input[type="password"] {
  width: 120%;
}

.registerPage form p {
  margin-top: 20px;
}

/* =================================================================================================== */

.limiter {
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
}
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: url("../../../../public/OflowAI-BG.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background-color: rgb(255 255 255 / 95%);*/
}
.wrap-login100 {
  overflow: hidden;
  border: 1px solid #007485;
  /* border: 1px solid #2c57ed; */
}
.wrap-login100 {
  width: 480px;
  background: #ffffffeb;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-t-30 {
  padding-top: 30px;
}
.login100-form-title {
  font-size: 28px;
  font-weight: 600;
  color: #007485;
  /* color: #2c57ed; */
  line-height: 1.2;
  text-align: center;
  display: block;
}
.p-b-41 {
  padding-bottom: 41px;
}
.login100-form {
  width: 100%;
  border-radius: 10px;
  /*background-color: #fff;*/
}
.p-b-33 {
  padding-bottom: 33px;
}
.p-t-5 {
  padding-top: 5px;
}
.login100-form {
  width: 100%;
}

.p-b-32 {
  padding-bottom: 32px;
}
.txt1 {
  font-size: 14px;
  color: #555;
  line-height: 3;
}
.p-b-11 {
  padding-bottom: 11px;
}
.validate-input {
  position: relative;
}
.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #007485;
  /* border: 1px solid #2c57ed; */
  border-radius: 50px;
}
.m-b-36 {
  margin-bottom: 36px;
}
.input100 {
  color: #555;
  line-height: 1.2;
  font-size: 15px;
  display: block;
  width: 100%;
  background: 0 0;
  height: 40px;
  padding: 0 25px;
}
input {
  outline: none;
  border: none;
}
input:-webkit-autofill,
input:-moz-autofill,
input:-ms-autofill {
  -webkit-text-fill-color: #555;
  border-radius: 50px;
}
.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #57b846;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}
.p-r-85 {
  padding-right: 85px;
}
.p-l-85 {
  padding-left: 85px;
}
.p-r-60 {
  padding-right: 60px;
}
.p-l-60 {
  padding-left: 60px;
}
.p-b-55 {
  padding-bottom: 55px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-t-20 {
  padding-top: 20px;
}
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login100-form-btn {
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 150px;
  height: 42px;
  background-color: #007485;
  /* background-color: #2c57ed; */
  border-radius: 27px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 600;
  width: 100%;
}
button {
  outline: none !important;
  border: none;
  background: 0 0;
}
.login100-form-btn:hover {
  background-color: #808080;
  /* background-color: #333; */
}
button:hover {
  cursor: pointer;
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #007485;
  /* border: 1px solid #2c57ed; */
  border-radius: 50px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}
textarea:focus,
.input100:focus {
  border-color: transparent !important;
}

.input-st {
  color: #555;
  font-size: 13px;
  line-height: 35px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-b-30 {
  padding-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .p-b-40 {
    padding-bottom: 30px;
  }
  .p-t-40 {
    padding-top: 24px;
  }
  .p-l-60 {
    padding-left: 30px;
  }
  .p-r-60 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 380px) {
  .txt1{
    font-size: 13px;
  }
}
@media screen and (max-width: 355px) {
  .txt1{
    font-size: 12px;
  }
}

/* ==================================================================== */

.background-shape {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.shape:first-child {
  background: linear-gradient(#007485, #58e3f8);
  left: -80px;
  top: -80px;
}
.background-shape .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}
.shape:last-child {
  background: linear-gradient(to right, #383838, #808080);
  right: -60px;
  bottom: -20px;
}

/* @media screen and (max-width: 768px) {
  .background-shape {
    display: none;
  }
} */


/* For mobile devices */
@media screen and (max-width: 767px) {
  .background-shape {
    width: 100%; /* Make the container full width for mobile */
    height: 100%; /* Cover full height */
  }

  .shape:first-child {
    width: 150px; /* Reduce the size for mobile */
    height: 150px;
    left: 0; /* Stick to the top left corner */
    top: 0;
    transform: translate(0, 0); /* Reset the center positioning */
  }

  .shape:last-child {
    width: 150px;
    height: 150px;
    right: 0; /* Stick to the bottom right corner */
    bottom: 0;
    transform: translate(0, 0); /* Reset the center positioning */
  }
}

@media screen and (max-width: 480px) {
  .shape:first-child {
    width: 150px; /* Further reduce size for smaller screens */
    height: 150px;
    transform: translate(0, 0);
    top:10%;
    left: 0;
  }

  .shape:last-child {
    width: 150px;
    height: 150px;
    transform: translate(0, 0);
    bottom:10%;
    right: 0;
  }
}

