.navbar {
  width: 100%;
  height: 60px;
  padding: 3px 20px;
  display: flex;
  color: white !important;
  justify-content: space-between;
  background-color: #007485;
  /* background-color: #1a7e8f; */
  /* background-color: #2c57ed; */
  /* background-color: #684b60; */
  /* background-color: #353535; */
  align-items: center;
  /* border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
}
.navbar .navleft {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 30px;
}
.navbar .navleft .logo {
  font-size: 24px;
  font-weight: 700;
}
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.right .profileIcon,
.right .profileName {
  font-size: 18px;
  /* color: rgb(61, 61, 61); */
}
.listItem {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  list-style-type: none;
}
.nav-link {
  color: rgb(223, 223, 223);
}
.nav-link:hover {
  color: white;
}
.active {
  color: white !important;
}
