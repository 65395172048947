.suf_container {
  width: 30vw;
  height: auto;
  position: absolute;
  top: 50px;
  right: 15px;
  background-color: #007485;
  color: white;
  border: 1px solid rgb(43, 42, 42);
  border-radius: 5px;
}

.suf_container > .suf_container_form {
  position: relative;
  width: 100%;
  min-height: 43vh;
  max-height: 62vh;
  padding: 30px 0px 25px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  overflow-y: scroll;
  z-index: 5;
}
.suf_container_form button.close_btn {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #dc3545;
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 20px;
  background-color: transparent;
  color: white;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.suf_container_form button.close_btn:hover {
  background-color: #dc3545;
}
.suf_container_form h4 {
  font-size: 18px;
}

.saf_tab_box {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  background-color: transparent;
  border-bottom: 1px solid #b9b9b9;
  padding-left: 0rem;
}
.saf_tab_box > li {
  width: 25%;
}
.saf_tab_box > li > button.saf_tab_button {
  width: 100%;
  border: none;
  color: rgb(212, 210, 210);
  background-color: transparent;
  padding: 3px;
  font-size: 13px;
}
.saf_tab_box > li > button.saf_tab_button:hover {
  border-top: 1px solid rgb(216, 214, 214);
  border-left: 1px solid rgb(216, 214, 214);
  border-right: 1px solid rgb(216, 214, 214);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: rgb(216, 214, 214);
  padding: 3px;
}

.saf_tab_box > li > button.active_tab {
  border-top: 1px solid white !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white !important;
  background-color: #808080;
}

.saf_tab_general_box,
.saf_tab_steps_box,
.saf_tab_views_box,
.saf_tab_doctype_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.suf_inpfield {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
}
.suf_inpfield label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.suf_inpfield textarea {
  width: 95%;
  border-radius: 5px;
  border: none;
  padding: 2px 2px 2px 10px;
  font-size: 14px;
  color: #494949;
}
.suf_sub_inpfield {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
}
.suf_sub_inpfield label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.suf_sub_inpfield select {
  background-color: white;
  width: 95%;
  font-size: 14px;
}

.suf_container_form input[type="text"],
.suf_container_form input[type="number"] {
  outline: none;
  width: 95%;
  height: 25px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  color: #494949;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.suf_container_form select {
  height: 25px;
  width: 90%;
  font-size: 13px;
  background-color: white;
}
.assignFormBtns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.assignFormBtns .newFormBtn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assignFormBtns button {
  font-size: 14px;
}
.conditional_link {
  cursor: pointer;
  font-size: 14px;
  color: white;
}
.conditional_link:hover {
  color: #ddd;
}
.condition_list_with_next {
  width: 100% !important;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.condition_list_with_next button {
  padding: 2px 5px;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
}
.condition_list_with_next button:hover {
  background-color: #c21d2e;
}

/* css code for conditions */
.suf_inpfield .condition_inputfield {
  width: 100%;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  gap: 10px !important;
  flex-wrap: wrap !important;
}
.condition_inputfield2 {
  margin-left: 15px;
  margin-top: 10px;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  gap: 10px !important;
  flex-wrap: wrap !important;
}
.suf_inpfield .condition_inputfield input {
  width: 35% !important;
  height: 30px !important;
  color: rgb(44, 44, 44);
  padding: 2px 4px;
  border: none !important;
  border-bottom: 2px solid rgb(59, 184, 142) !important;
  outline: none;
}
.condition_inputfield2 input {
  width: 35% !important;
  height: 30px !important;
  font-size: 14px;
  color: rgb(44, 44, 44);
  padding: 2px 4px;
  border: none !important;
  border-bottom: 2px solid rgb(59, 184, 142) !important;
  outline: none;
}
.condition_inputfield select,
.condition_inputfield2 select {
  width: 50px;
  height: 25px;
  background-color: white;
  border: none;
  border-bottom: 1px solid rgb(59, 184, 142);
  outline: none;
  appearance: none;
  text-align: center;
}

.condition_list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: rgb(39, 39, 39);
}
.condition_list button {
  padding: 2px 5px;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
}
.condition_list button:hover {
  background-color: #c21d2e;
}
.suf_condition_buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
}
.suf_condition_add_button,
.suf_condition_close_button {
  border: none !important;
  font-size: 20px !important;
  padding: 0 !important;
  color: white !important;
  background-color: transparent !important;
}
.suf_condition_add_button:hover {
  color: #01939a !important;
}
.suf_condition_close_button:hover {
  color: #f34a5b !important;
}

li button.dropdown-item:hover {
  background-color: #ebebeb;
}

.pb_save_button {
  margin-top: 20px;
  justify-self: end !important;
  width: 90%;
  margin-bottom: 0px;
  background-color: #01939a;
  color: white;
}
.pb_save_button:hover {
  background-color: #028188;
}

.suf_group_access_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}
.suf_group_access_box ul {
  width: 100%;
  display: flex;
  margin-bottom: 0rem;
  padding-left: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
ul .suf_group_access_list {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}
ul .suf_group_access_list .suf_group_access_name_span {
  display: inline-block;
  width: 150px;
  text-align: left;
  font-size: 14px;
  word-wrap: break-word;
  line-break: anywhere;
  line-clamp: 3;
}

.suf_group_access_delete {
  border: none;
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
}
.suf_group_access_delete:hover {
  color: #f34a5b;
}

.suf_group_access_select_box {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.suf_group_access_close_button {
  border: none;
  font-size: 20px;
  padding: 0;
  color: white;
  background-color: transparent;
}
.suf_group_access_close_button:hover {
  color: #f34a5b;
}
.suf_group_access_show_select {
  padding-left: 5px;
  font-size: 14px;
  align-self: flex-start;
  cursor: pointer;
}
.suf_group_access_show_select:hover {
  color: #ddd;
}

.parent_component_container {
  width: 90%;
  margin-left: 15px;
  margin-top: 5px;
}

.condition_wrapper {
  width: 100%;
}
.condition_control_button {
  margin-top: 2px;
  margin-right: 5px;
  border: 1px solid #686868;
  border-radius: 10px;
  padding: 1px 5px;
  font-size: 13px;
  color: white;
  background-color: #808080;
}
.condition_control_button:hover {
  background-color: #686868;
}
.condition_delete_button {
  padding: 3px 3px;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.condition_delete_button:hover {
  background-color: #c21d2e;
}

/* css code for doctype  */
/* .docType_buttons{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  align-self: flex-start;
  padding-left: 20px;
} */
/* .docType_create_btn {
  font-size: 13px;
  border: 1px solid rgb(44, 44, 44);
  padding: 5px 7px;
  border-radius: 10px;
  background-color: rgb(59, 59, 59);
  color: white;
}
.docType_create_btn:hover {
  background-color: rgb(44, 44, 44);
} */
.create_kanban_button,
.docType_create_btn {
  position: relative;
  left: 5px;
  width: 9rem;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #fff;
  padding: 5px 7px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #007485;
  transition: all;
  transition-duration: 300ms;
}
.create_kanban_button:hover,
.docType_create_btn:hover {
  width: 9.5rem;
  left: 0px;
}

.templateForm_buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  align-self: flex-start;
  padding-left: 20px;
}
.templateForm_btn {
  border: 1px solid #585858;
  font-size: 13px;
  padding: 2px 7px;
  border-radius: 10px;
  background-color: #808080;
  color: white;
}
.templateForm_btn:hover {
  background-color: #585858;
}

.template_edit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 25px;
  background-color: #808080;
  border: 1px solid #585858;
  color: white;
  border-radius: 5px;
  &:hover {
    background-color: #585858;
  }
}
.template_remove_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 25px;
  background-color: #f34a5b;
  border: 1px solid #f72136;
  color: white;
  border-radius: 5px;
  &:hover {
    background-color: #f72136;
  }
}

.doctype_edit_btn {
  width: 30px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #484848;
  border: none;
  border-radius: 3px;

  &:hover {
    background-color: #383838;
  }
}
.doctype_del_btn {
  width: 30px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 3px;

  &:hover {
    background-color: #c21d2e;
  }
}
