.fb_detailUpdate {
  width: 18vw;
  height: 87vh;
  border: 1px solid #007485;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  background-color: #007485;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px !important;
  -webkit-user-select: none;
  user-select: none;
}
.fb_detailUpdate h2 {
  margin-top: 15px;
}
.fb_field_details {
  position: relative;
  width: 97%;
  height: 97%;
  overflow-y: auto;
}
.fb_field_details .fb_field_details_btn {
  position: absolute;
  left: 10px;
  top: 10px;
  /* border: 1px solid rgb(110, 110, 110); */
  width: 30px !important;
  height: 30px !important;
  padding: 3px !important;
  border-radius: 5px;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px !important;
  font-size: 16px !important;
}
.fb_field_details .fb_field_details_btn span {
  font-size: 14px !important;
}
.fb_field_details_btn:hover {
  background-color: #bb2d3b;
}
.fb_field_details .fb_field_details_btn .fb_field_details_btn_icon {
  font-size: 30px !important;
}
.fb_field_details_form {
  width: 100%;
  /* height: 60vh; */
  margin-top: 45px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* overflow: scroll; */
}

.fb_field_details_form_group {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
}
.fb_field_details_form_group label {
  font-size: 15px;
  /* font-weight: 400; */
}
.fb_field_details_form_group input,
.fb_field_details_form_group select {
  width: 100%;
  height: 30px;
  outline: none;
  color: rgb(95, 95, 95);
  border: 1px solid #ddd;
  padding: 2px 2px 2px 10px;
  border-radius: 5px;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
}
.fb_field_details_form_group select {
  background-color: white;
}
.fb_field_details_form_submit {
  margin: 15px 0px;
  /* border: 1px solid rgb(110, 110, 110); */
  width: 90%;
  height: 35px;
  padding: 3px;
  border-radius: 5px;
  /* background-color: white; */
  font-weight: 600;
  background-color: #fff;
  /* background-color: #01939a; */
  color: #007485;
  transition: all;
  transition-duration: 300ms;
}
.fb_field_details_form_submit:hover {
  width: 95%;
  color: #007485;
  background-color: #fff;
}
.fb_field_details_form_group_checkbox_container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 15%;
}
.fb_field_details_form_group_checkbox_container label {
  font-size: 15px;
}
.fb_field_details_form_group_checkbox_container input[type="checkbox"] {
  width: 17px;
  height: 17px;
  outline: none;
  color: rgb(56, 56, 56);
  align-self: center;
  cursor: pointer;
  padding: 2px 2px 2px 10px;
  border-radius: 5px;
}

.select_option_edit,
.select_option_delete {
  width: 25px;
  height: 25px;
  color: white;
  border-radius: 5px;
  background-color: transparent;
  border: none;
}
.select_option_edit:hover {
  background-color: #7c7c7c;
}
.select_option_delete:hover {
  background-color: #bb2d3b;
}
.opt_add_button:hover {
  background-color: cadetblue !important;
}
.code_editor_button {
  width: 90%;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  background-color: #218ea0;
  /* background-color: #0d6efd; */
  letter-spacing: 1px;
  box-shadow: 0px 0px 2px #2c2c2c;
}
.code_editor_button:hover {
  background-color: #198ca0;
  /* background-color: #0a51bb; */
  box-shadow: 0px 0px 2px #fafafa;
}
.cde_edit_button {
  padding: 3px 5px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #4e4e4e;
}
.cde_edit_button:hover {
  background-color: #3f3f3f;
}

.fdu_group_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.fdu_group_box > ul {
  width: 100%;
  display: flex;
  margin-bottom: 0rem;
  padding-left: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.fdu_group_list {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.fdu_group_list > .fdu_group_name_span {
  display: inline-block;
  width: 150px;
  text-align: left;
  font-size: 14px;
  word-wrap: break-word;
  line-break: anywhere;
  line-clamp: 3;
}
.fdu_group_input_box {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.fdu_group_input_box > select {
  width: 90%;
  font-size: 13px;
}
.fdu_group_add_button,
.fdu_group_close_button {
  border: none;
  font-size: 20px;
  padding: 0;
  color: white;
  background-color: transparent;
}
.fdu_group_show_input {
  padding-left: 5px;
  font-size: 14px;
  align-self: flex-start;
  cursor: pointer;
}
.fdu_group_show_input:hover {
  color: #aaa;
}

.fdu_group_add_button:hover {
  color: #aaaaaa;
}
.fdu_group_close_button:hover {
  color: #f34a5b;
}
.fdu_group_delete {
  border: none;
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
}
.fdu_group_delete:hover {
  color: #f34a5b;
}

.selection_option_add_more_button {
  font-size: 14px;
  color: white;
}
.selection_option_add_more_button:hover {
  color: #aaa;
}

/* code for custom checkbox css */

/* The container */
.checkbox_container {
  height: 20px;
  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eeeeee;
  border: 2px solid #9c9c9c;
  border-radius: 3px;
  /* border: 1px solid #fff; */
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark {
  background-color: #cecece;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked ~ .checkmark {
  background-color: #808080;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.label_checkbox {
  font-size: 14px;
}

.todos_del_button {
  background-color: #dc3545;
  color: #fff;
  width: 30px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #c82333;
  }
}
.todos_edit_button {
  background-color: #383838;
  color: #fff;
  width: 30px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #4e4e4e;
  }
}
