.modal-body {
  padding: 1rem 3rem;
}
.modal-header {
  background-color: #007485;
  color: white !important;
}
.modal-body .processModalForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
}
.modal-body .processModalForm .processModalForm_inputField {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.modal-body .processModalForm .processModalForm_inputField label {
  font-size: 18px;
  font-weight: 500;
  color: rgb(77, 77, 77);
}
.modal-body
  .processModalForm
  .processModalForm_inputField
  input:not(input[type="file"]) {
  width: 95%;
  height: 35px;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 2px 40px 2px 10px;
  color: rgb(77, 77, 77);
}
.modal-body .processModalForm .processModalForm_inputField input[type="file"] {
  width: 95%;
  outline: none;
  border: 1px solid gray;
}
.modal-body .processModalForm .processModalForm_inputField button {
  width: 35px;
  top: 32px;
  height: 40px;
  position: absolute;
  right: 20px;
  outline: none;
  border: 1px solid gray;
  color: rgb(77, 77, 77);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.processModalFormSelectField {
  height: 40px;
  outline: none;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2px 40px 2px 10px;
  color: rgb(77, 77, 77);
  background-color: white;
}

.modal_save_button {
  background-color: #1a7e8f;
  color: white;
  &:hover {
    background-color: #109ab3;
    color: white;
  }
}
