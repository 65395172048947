.quill {
  width: 80vw;
}
.ql-container {
  min-height: 60vh;
  overflow-y: auto;
}
.cde_save_code {
  margin-left: 20px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #198754;
}

.cde_save_code:hover {
  background-color: #147548;
}
