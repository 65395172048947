.form_modal_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form_modal_body,
.form_modal_header,
.form_modal_footer,
.form_create_modal {
  width: 100%;
}

.modal-body .formbuilderModalForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal-body .formbuilderModalForm .formbuilderModalForm_inputField {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.modal-body .formbuilderModalForm .formbuilderModalForm_inputField label {
  font-size: 18px;
  font-weight: 500;
  color: rgb(77, 77, 77);
}
.modal-body .formbuilderModalForm .formbuilderModalForm_inputField input {
  width: 90%;
  height: 40px;
  outline: none;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2px 2px 2px 10px;
  color: rgb(77, 77, 77);
}
