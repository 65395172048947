.condition_field_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 3px 0px;
}
.condition_field_container input {
  width: 100%;
  padding: 2px 2px 2px 10px;
  border-radius: 3px;
  height: 30px;
  color: rgb(58, 57, 57);
  outline: none;
  background-color: white;
}
.condition_field_container select {
  width: 40px;
  height: 20px;
  padding: 0px;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.condition_field_operand_selection {
  width: 100% !important;
  height: 25px !important;
  background-color: white !important;
  -webkit-appearance: auto !important;
  appearance: auto !important;
  padding: 2px 2px 2px 5px !important;
}
.condition_field_remove_btn {
  border: none;
  background-color: transparent;
  padding: 0;
  color: white;
}
.condition_field_remove_btn:hover {
  color: #f34a5b;
}
