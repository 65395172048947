@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@media print {
  body {
    margin: 0 !important;
  }
}
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  width: 560px;
}

.main-container {
  font-family: "Lato";
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #383838;
}

.ck-content {
  font-family: "Lato";
  line-height: 1.6;
  word-break: break-word;
  min-height: 200px;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 100%;
  max-width: 100%;
}

.ck.ck-powered-by {
  display: none;
}

.variable-selector {
  margin-bottom: 10px;
  width: 45%;
}

select.email_template_variable_select {
  padding: 5px;
  margin-left: 5px;
  width: 90%;
  font-size: 14px;
}

.attachment-upload {
  position: relative;
  margin: 3px 0;
  width: 47%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload_attachment_input {
  width: 30px;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}
.upload_attachment_input {
  cursor: pointer;
}
.upload_attachment_icon {
  position: absolute;
  right: 35%;
  width: 30px;
  height: 22px;
  opacity: 1;
  cursor: pointer;
  z-index: 5;
  color: #383838;
  &:hover {
    /* color: #ddd; */
    cursor: pointer;
  }
}

.attachments-list ul {
  list-style-type: none;
  padding: 0;
}

.attachments-list li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 3px 0;
}

button.email_attachment_remove_btn {
  background-color: #007485;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
}

button.email_attachment_remove_btn:hover {
  background-color: #005f5f;
}
