.domain_component{
    width: 100%;
}
.domain_condition_box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.domain_condition_field{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.fdu_domain_condition_remove_btn {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #bbbbbb;
  /* color: white; */
}
.fdu_domain_condition_remove_btn:hover {
  color: #f34a5b;
}

.domain_condition_btn {
  margin-top: 2px;
  margin-right: 5px;
  border: 1px solid #808080;
  /* border: 1px solid rgb(44, 44, 44); */
  border-radius: 10px;
  padding: 2px 7px;
  font-size: 13px;
  color: white;
  background-color: #808080;
  /* background-color: rgb(71, 71, 71); */
}
.domain_condition_btn:hover {
  background-color: #686868;
  /* background-color: rgb(44, 44, 44); */
}
.domain_condition_operand{
    width: 100%;
    font-size: 14px !important;
}
.domain_condition_logic_operator{
    margin: 2px 0px;
    width: 50px !important;
    height: 25px !important;
    padding-left: 5px !important;
    text-align: center;
     font-size: 14px !important;
    /* background-color: #ddd !important; */
    /* appearance: none; */
}
.domain_condition_operator{
    width: 50px !important;
    padding-left: 0px !important;
    text-align: center;
    appearance: none;
     font-size: 14px !important;
}