div.control-panel {
  width: 160px;
  /* width: 11vw; */
  position: absolute;
  left: 15px;
  top: 50px;
  /* min-height: 70vh; */
  max-height: 75vh;
  /* height: auto; */
  display: flex !important;
  padding: 5px !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 10px;
  /* background-color: #353535; */
  background-color: #007485;
  /* background-color: #2c5fed; */
  /* background-color: #684b60; */
  /* border: 1px solid gray; */
  z-index: 10;
  text-align: center;
  overflow-y: auto;
}
div.control-panel .close_panel_button {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #dc3545;
  border-radius: 5px;
}
div.control-panel .close_panel_button:hover {
  background-color: #dc3545;
}
div.control-panel h5 {
  margin-top: 30px;
  width: 110px;
  font-size: 16px;
  color: white;
}
.cp_activity_group {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.control_btn {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: wrap;
  row-gap: 2px !important;
  column-gap: 20px !important;
  padding-left: 15px;
  max-width: 120px;
}
.cp_event_box {
  display: flex !important;
  background-color: #808080;
  /* background-color: #1336aa; */
  /* background-color: #805454; */
  justify-self: flex-start;
  width: 100%;
  color: white;
  font-weight: 500;
  font-size: 13px;
  padding-left: 5px;
  border-radius: 2px;
  /* text-align: left; */
}
.control_btn button {
  padding: 5px;
  /* background-color: white; */
  background-color: transparent;
  color: white;
  border-radius: 10px;
  outline: none;
  /* border: 1px solid #090909; */
  border: none;
  width: 35px;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.control_btn > button > span {
  font-size: 12px;
}
.access_buttons {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.access_buttons button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  /* background-color: #0d6efd; */
  background-color: #01939a;
}
.access_buttons button:hover {
  /* background-color: #0c5ed8; */
  background-color: #00747a;
}
.jsondata {
  display: flex !important;
  /* width: 28vw;
    height: 25vh; */
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 10px;
  /* padding-left: 40px; */
  /* overflow: scroll; */
}
.jsonbtn {
  width: 110px !important;
  height: 50px !important;
  color: white;
  background-color: rgb(68, 95, 28);
  border-radius: 10px;
}
.jsonbtn:hover {
  background-color: rgb(48, 145, 48);
}
.jsontext {
  width: 100%;
  height: auto;
  text-align: left;
  word-wrap: break-word;
}
