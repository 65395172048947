div.selectedForm{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* color: white; */
}
div.selectedForm span{
    max-width: 200px;
    position: relative;
    top: 0;
    left:0;
    font-size: 13px;
    letter-spacing: 0.4px;
    color: #dadada;
    word-wrap: break-word;
    line-break: anywhere;
    word-break: break-all;
}
div.selectedForm span:hover{
    background-color: transparent;
    border: none;
    cursor: default;
}
.selectedForm .selectedFormBtns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.selectedForm .selectedFormBtns button{
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}