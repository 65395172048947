.logout {
  width: 120px;
  height: 100px;
  position: absolute;
  top: 60px;
  right: 20px;
  border-radius: 5px;
  background-color: white !important;
  border: 1px solid rgb(202, 202, 202);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: 0px 0px 15px;
  z-index: 115;
  opacity: 1;
}
.logout .logout_quit_btn {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.logout .logout_quit_btn:hover {
  background-color: #808080;
  color: white;
}
.logout_button {
  width: 150px;
  height: 30px;
  border: 1px solid #007485;
  color: #007485;
  border-radius: 5px;
  &:hover {
    background-color: #007485;
    color: white;
  }
}
