.App {
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
/* .App.auth-page {
  margin-top: 100px !important;
} */

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background-color: #007485 !important;
}

/* Custom styling for the buttons */
.swal-confirm {
  background-color: #007485; /* Default blue for the confirm button */
  color: white;
}

.swal-deny {
  background-color: gray; /* Gray background for the Copy Form button */
  color: white;
}

.swal-cancel {
  background-color: #dc3545; /* Red background for the Cancel button */
  color: white;
}

/* Add hover effects if desired */
.swal-confirm:hover {
  background-color: #015b69;
}

.swal-deny:hover {
  background-color: #6c757d;
}

.swal-cancel:hover {
  background-color: #c21d2e;
}
