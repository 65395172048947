.formbuilder {
  padding-top: 35px;
  /* margin-top: 35px; */
  width: 100%;
  /* width: 99vw; */
  height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: white;
  overflow: hidden;
}
.fb_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
