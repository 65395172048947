.actionModalForm_inputfields {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-start;
  /* gap: 10px; */
}

.modal-body .actionModalForm_inputfields .formbuilderModalForm_inputField {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.modal-body
  .actionModalForm_inputfields
  .formbuilderModalForm_inputField
  label {
  font-size: 18px;
  font-weight: 500;
  color: rgb(77, 77, 77);
}
.modal-body
  .actionModalForm_inputfields
  .formbuilderModalForm_inputField
  input {
  width: 90%;
  height: 40px;
  outline: none;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2px 2px 2px 10px;
  color: rgb(77, 77, 77);
}

.action_map_both_button {
  border: 1px solid #ddd;
  padding: 3px 20px;
  border-radius: 5px;
  color: white;
  background-color: #007485;
  &:hover {
    background-color: #005f6d;
  }
}
