.docType_buttons{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  align-self: flex-start;
  padding-left: 20px;
}
.docType_btn{
  border: 1px solid rgb(44, 44, 44);
  font-size: 13px;
  padding: 2px 7px;
  border-radius: 10px;
  background-color: rgb(59, 59, 59);
  color: white;
}
.docType_btn:hover{
  background-color: rgb(44, 44, 44);
}
.doctype_form_head_label{
    margin-left: 10px;
    align-self: flex-start;
    font-weight: 600;
}