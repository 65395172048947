.fb_control {
  width: 19vw;
  height: 87vh;
  padding: 20px 5px;
  border: 1px solid #007485;
  color: white;
  background-color: #007485;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  overflow-y: auto;
}
.fb_control div.fb_form_panel {
  width: 100%;
}
.fb_control div span.headSpan {
  margin-left: 5px;
  color: white;
  align-self: flex-start;
}

.fcontrol_btns {
  margin-top: 5px;
  margin-left: 5px;
  width: 100%;
  height: auto;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px !important;
}

.fcontrol_btn {
  border: 1px solid #c5c5c5;
  /* border: 1px solid #333333; */
  width: 47%;
  height: 30px;
  padding-left: 5px;
  border-radius: 5px;
  background-color: #007485;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  cursor: grab !important;
  color: white;
  box-shadow: 0px 0px 3px #393939;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fcontrol_btn span {
  width: 80%;
  text-align: left;
  font-size: 12px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fieldicon {
  font-size: 25px !important;
}
.kb_kanban_btn_group {
  flex-wrap: wrap !important;
  width: 100% !important;
}
.form_field_btn_group {
  flex-wrap: wrap !important;
  width: 100% !important;
}
.kb_kanban_btn_group .fcontrol_btns {
  margin-top: 0 !important;
  margin-left: 0 !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
}
.kb_kanban_btn_group .fcontrol_btns .fcontrol_btn {
  width: 115px;
}
.kb_kanban_btn_group .fcontrol_btns .fcontrol_btn span {
  font-size: 11px;
}
.kb_kanban_btn_group .fcontrol_btns .fcontrol_btn .fieldicon {
  font-size: 20px !important;
}
