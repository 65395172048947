.custom-select {
  width: 90% !important;
  position: relative;
  width: 200px;
  font-family: Arial, sans-serif;
}

.custom-select__control {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid #acaaaa;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.custom-select__control:hover {
  border-color: rgb(255, 255, 255);
}

.arrow {
  transition: transform 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
}

.custom-select__menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  color: #4e4d4d;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: drop 0.3s ease-out;
  z-index: 1000;
}

.custom-select__option {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-select__option:hover {
  color: #fff;
  background-color: #218ea0;
}

@keyframes drop {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

select.existing_form_select {
  width: 100%;
  height: 35px;
  padding: 5px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #ccc;
  color: rgb(255, 255, 255);
}
select.existing_form_select > option.existing_form_select_option {
  padding: 5px;
  /* color: white; */
  color: rgb(37, 37, 37);
  border-radius: 3px;
}
select.existing_form_select > option.existing_form_select_option:hover {
  color: white;
  background: #218ea0 !important;
}

.custom-select > .suf_inpfield {
  width: 100%;
}
