.configure_page_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  padding: 15px 20px;
  width: 70vw;
  /* height: 65vh; */
  background-color: #ffffff;
  /* background-color: #f0f0f0; */
  border-radius: 5px;
  box-shadow: 0px 0px 5px #3699aa;
}
.configure_page_form h2 {
  margin-bottom: 40px;
}
.configure_page_form {
  width: 83%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #007485;
  /* color: #684b60; */
}
.configure_page_form label {
  width: 20ch;
}
.configure_page_form input {
  width: 100%;
  height: 45px;
  color: #505050;
  border: 1px solid rgb(175, 175, 175);
}
.configure_page_form input::placeholder {
  color: #a5a5a5;
}
.configure_selection_container {
  width: 100%;
}
.configure_page_select {
  /* width: 195% !important; */
  height: 45px !important;
  padding: 0.375rem 0.75rem !important;
  color: #505050;
  border: 1px solid rgb(175, 175, 175);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.configure_msg_container {
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.password_eye_button {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  color: #505050;
  background-color: transparent;
  font-size: 25px;
}
.configure_detail_security_input {
  width: max-content !important;
}

.subscription_page_select {
  width: 90% !important;
  height: 45px !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505050;
  border: 1px solid #aaa;
}
