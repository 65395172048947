.cc_container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action_delete_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  color: white;
  background-color: #f44336;
  border-radius: 5px;
  &:hover {
    background-color: #f82415;
  }
}
.action_map_field_button {
  display: flex;
  position: relative;
  width: 90px;
  left: 5px;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  background-color: white;
  color: #007485;
  border-radius: 5px;
  transition: all;
  transition-duration: 300ms;
  &:hover {
    width: 100px;
    left: 0px;
  }
}
