.process-moeler-page {
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
}
.progress_bar_checkin {
  position: absolute;
  top: 30%;
  z-index: 10;
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  /* -webkit-backdrop-filter: blur( 5px );
  backdrop-filter: blur( 5px ); */
}
.menu_cp {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 55px;
  left: 20px;
  background-color: #007485;
  /* background-color: #684b60; */
  /* background-color: #353535; */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
  transition: all;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}
.menu_cp:hover {
  left: 17px;
  top: 53px;
  /* background-color: #684b60; */
  background-color: #007485;
  /* background-color: #0c0c0c; */
  width: 40px;
  height: 40px;
}

.board {
  width: 100vw;
  height: 91vh;
  /* border: 2px solid black; */
}
.react-flow__attribution a {
  display: none !important;
}
.react-flow__controls {
  display: flex;
  flex-direction: row !important;
}
.pm_process_save_btn {
  position: absolute;
  top: 7px;
  left: 20px;
  /* left: 15px; */
  width: 130px;
  /* width: 130px; */
  /* height: 35px; */
  color: #007485;
  /* color: #2c57ed; */
  /* color: #fff; */
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  /* background-color: #474747; */
  border: 1px solid #007485;
  /* border: 1px solid #2c57ed; */
  /* border: none; */
  border-radius: 5px !important;
  padding: 7px 10px;
  transition: all;
  transition-duration: 300ms;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.pm_process_save_btn:hover {
  width: 140px;
  /* width: 125px; */
  left: 15px;
  /* left: 17.5px; */
  color: #fff;
  background-color: #007485;
  /* background-color: #2c57ed; */
  /* background-color: #1d1d1d; */
}
.pm_process_server_rst_btn {
  position: absolute;
  top: 7px;
  left: 170px;
  width: 150px;
  color: #007485;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid #007485;
  border-radius: 5px !important;
  padding: 7px 10px;
  transition: all;
  transition-duration: 300ms;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:hover {
    width: 160px;
    left: 165px;
    color: #fff;
    background-color: #007485;
  }
}

.pm_shape_del_btn {
  width: 15px;
  position: absolute;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 13px;
}
.pm_shape_del_btn:hover {
  color: #dc3545;
}
