.modal-body {
  padding: 1rem 3rem;
}
.modal-body .importProcessModalForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
}
.modal-body .importProcessModalForm .importProcessModalForm_inputField {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.modal-body .importProcessModalForm .importProcessModalForm_inputField_fetch {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.modal-body .importProcessModalForm .importProcessModalForm_inputField label {
  font-size: 18px;
  font-weight: 500;
  color: rgb(77, 77, 77);
}
.modal-body
  .importProcessModalForm
  .importProcessModalForm_inputField_fetch
  label {
  font-size: 16px;
  font-weight: 500;
  width: 17ch;
  color: rgb(77, 77, 77);
}
.modal-body .importProcessModalForm .importProcessModalForm_inputField_fetch p {
  width: 100%;
  margin-bottom: 0rem;
}
.modal-body
  .importProcessModalForm
  .importProcessModalForm_inputField
  input:not(input[type="file"]) {
  width: 95%;
  height: 40px;
  outline: none;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2px 40px 2px 10px;
  color: rgb(77, 77, 77);
}
.modal-body
  .importProcessModalForm
  .importProcessModalForm_inputField
  input[type="file"] {
  width: 95%;
  outline: none;
  border: 1px solid gray;
}
.modal-body .importProcessModalForm .importProcessModalForm_inputField button {
  width: 35px;
  top: 32px;
  height: 40px;
  position: absolute;
  right: 20px;
  outline: none;
  border: 1px solid gray;
  color: rgb(77, 77, 77);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.modal_process_update_button {
  color:#1a7e8f;
  border: 1px solid #1a7e8f;
  &:hover {
    background-color: #1a7e8f;
    color: white;
  }
}

.modal_save_button{
    background-color: #1a7e8f;
    color: white;
    &:hover {
        background-color: #109ab3;
        color: white;
    }
}